<template>
  <b-overlay
    variant="light"
    :show="loading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-card>
      <div class="flex items-start">
        <div class="flex flex-col sm:flex-row items-center justify-between gap-2 w-full mb-2">
          <div class="flex">
            <b-button
              variant="primary"
              size="sm"
              class="mr-1 rounded-lg p-0 h-[30px]"
              @click="$emit('back')"
            >
              <feather-icon
                size="2x"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <div class="text-black text-2xl font-semibold">
              Detail Pengajuan Packing
            </div>
          </div>
          <div>
            <b-badge
              :variant="status === 'Dipacking' ? 'light-info' : status === 'Dibatalkan' ? 'light-secondary' : 'light-warning'"
              class="mr-2 px-2 py-1"
              pill
            >
              {{ status }}
            </b-badge>
            <b-button
              v-if="status === 'Diajukan'"
              variant="primary"
              @click="cancelPacking"
            >Batalkan</b-button>
          </div>
        </div>
      </div>
      <div class="border rounded-lg p-6 space-y-6">
        <div class="grid grid-cols-1 md:grid-cols-3 !gap-5 text-black">
          <div class="">
            <div class="mb-[12px] text-lg font-medium">
              Gudang
            </div>
            <div class="text-[#626262]">
              {{ warehouse.name }}
            </div>
            <div class="text-sm text-[#828282] !capitalize">
              {{ warehouse.location }}
            </div>
          </div>
          <div class="">
            <div class="mb-[12px] text-lg font-medium">
              Label Pengiriman
            </div>
            <div
              v-if="!loading"
              class="d-flex justify-content-between items-center gap-[5px]"
            >
              <div class="min-w-0">
                <b-img
                  :src="label_name ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-text.svg' : 'https://storage.googleapis.com/komerce/icon/error_file.svg'"
                  width="24"
                  height="24"
                  alt="label"
                  class="inline mr-[5px] self-center"
                />
                <b-link
                  class="underline !text-primary"
                  :href="label_url"
                  target="_blank"
                >{{ label_name || "File Error" }}
                </b-link>
                <b-form-file
                  ref="label_ref"
                  v-model="label_file"
                  accept="application/pdf"
                  class="mt-3"
                  plain
                  hidden="true"
                />
              </div>
              <b-button
                v-if="!label_name"
                variant="primary"
                size="sm"
                @click="$refs.label_ref.$el.click()"
              ><i class="k-export-2 mr-[10px]" /><span>Reupload</span></b-button>
              <b-button
                v-if="label_file"
                variant="primary"
                size="sm"
                class="d-flex items-center"
                @click="uploadLabel"
              ><b-spinner
                v-if="reupload_inprogress"
                small
              /><span class="ml-[5px]">{{ reupload_inprogress ? "Menyimpan..." : "Simpan" }}</span></b-button>
            </div>
            <div
              v-else
              class="text-[#626262]"
            >
              -
            </div>
          </div>
          <div class="">
            <div class="mb-[12px] text-lg font-medium">
              Jumlah Orderan pada Resi
            </div>
            <div class="text-[#626262]">
              {{ loading ? '-' : grand_total_order }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 space-y-6">
        <div class="text-black">
          Rangkuman Order
        </div>
        <BTableSimple bordered>
          <BThead>
            <BTr class="!text-black">
              <BTh class="!capitalize">
                Jenis Order
              </BTh>
              <BTh class="!capitalize">
                Produk
              </BTh>
              <BTh class="!capitalize">
                Variasi
              </BTh>
              <BTh class="!capitalize">
                Jumlah Item
              </BTh>
              <BTh class="!capitalize">
                Jumlah Order
              </BTh>
              <BTh class="!capitalize">
                Bahan Packing
              </BTh>

              <BTh
                v-if="status === 'Dipacking'"
                class="!capitalize"
                style="width: 190px !important;"
              >
                Disetujui Mitra
              </BTh>
            </BTr>
          </BThead>
          <BTbody>
            <BTr
              v-for="(item, index) in items"
              :key="index"
              class="!text-black"
            >
              <!-- Order Number Cell with Rowspan -->
              <BTd
                v-if="item.orderRowspan > 0"
                :rowspan="item.orderRowspan"
                class="align-top"
              >
                {{ item.order_number }}
              </BTd>
              <!-- Product Name -->
              <BTd
                v-if="item.productRowspan > 0"
                :rowspan="item.productRowspan"
                class="align-top"
              >
                {{ item.product_name }}
              </BTd>
              <!-- Product Variant Name -->
              <BTd class="align-top">
                {{ item.product_variant_name }}
              </BTd>
              <!-- Total Item -->
              <BTd
                v-if="item.productRowspan > 0"
                :rowspan="item.productRowspan"
                class="align-top"
              >
                {{ item.total_item }}
              </BTd>
              <!-- Total Order Cell with Rowspan -->
              <BTd
                v-if="item.orderRowspan > 0"
                :rowspan="item.orderRowspan"
                class="align-top"
              >
                {{ item.total_order }}
              </BTd>
              <!-- Packing Detail Cell -->
              <BTd
                v-if="item.orderRowspan > 0"
                :rowspan="item.orderRowspan"
                class="align-top"
              >
                <p v-if="!item.packings">
                  -
                </p>
                <ul
                  v-else
                  class="list-disc ml-2"
                >
                  <li
                    v-for="material in item.packings"
                    :key="index + material"
                  >{{ material }}</li>
                </ul>
              </BTd>
              <!-- Total Order Approved Cell with Rowspan -->
              <BTd
                v-if="item.orderRowspan > 0 && status === 'Dipacking'"
                :rowspan="item.orderRowspan"
                style="width: 190px !important;"
                class="align-top"
              >
                <span>{{ item.total_order_approved }}</span>
              </BTd>
            </BTr>
          </BTbody>
        </BTableSimple>
        <div class="text-black space-y-2">
          <b-row align-h="end">
            <b-col cols="2">
              Total Order
            </b-col>
            <b-col cols="0">
              :
            </b-col>
            <b-col cols="2">
              <span>
                {{ grand_total_order }}
              </span>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols="2">
              Total Biaya
            </b-col>
            <b-col cols="0">
              :
            </b-col>
            <b-col cols="2">
              <span>
                {{ grand_fulfillment_fee | rupiah }}
              </span>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { BFormFile, BSpinner, BButton } from 'bootstrap-vue'
import { newAxiosIns } from '@/libs/axios'
import { toast_error, toast_success } from '@/libs/toastification'
import filters from '@/libs/filters'

export default {
  filters: { rupiah: filters.rupiah },
  props: {
    detailId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      refId: this.$route.query.ref_id,
      label_file: null,
      loading: false,
      items: [],
      status: '',
      label_url: '',
      label_name: '',
      grand_total_order: 0,
      grand_fulfillment_fee: 0,
      warehouse: {
        name: '-',
        location: '-',
      },
      reupload_inprogress: false,
    }
  },
  watch: {
    label_file(new_value) {
      if (new_value.name) this.label_name = new_value.name
      this.label_url = URL.createObjectURL(new_value)
    },
  },
  created() {
    this.fetchDetailPacking()
  },
  methods: {
    uploadLabel() { // Reuploads the label file to the server and handles the upload process.
      this.reupload_inprogress = true
      const formData = new FormData()
      formData.append('label', this.label_file)

      newAxiosIns
        .put(`/kompack/api/v1/outbounds/${this.refId ? this.refId : this.detailId}/label`, formData)
        .then(() => {
          toast_success({
            title: 'Berhasil!',
            message: 'Label pengiriman berhasil diunggah.',
          })
          this.label_file = null
          this.fetchDetailPacking()
        })
        .catch(() => {
          toast_error({
            title: 'Gagal!',
            message: 'Terjadi kesalahan, file kamu gagal diunggah. Coba lagi nanti.',
          })
          this.label_name = null
          this.label_file = null
        })
        .finally(() => {
          this.reupload_inprogress = false
        })
    },
    async fetchDetailPacking() {
      this.loading = true
      await newAxiosIns
        .get(
          `/kompack/api/v1/outbounds/${this.refId ? this.refId : this.detailId}`,
        )
        .then(res => {
          const { data } = res.data

          switch (data.status) {
            case 'confirmed':
              this.status = 'Dipacking'
              break
            case 'canceled':
              this.status = 'Dibatalkan'
              break
            default:
              this.status = 'Diajukan'
          }

          // this.status = data.status === 'confirmed' ? 'Dipacking' : data.status === 'canceled' ? 'Dibatalkan' : 'Diajukan'
          this.label_url = data.label_url
          this.label_name = data.label_name
          this.grand_total_order = data.grand_total_order
          this.grand_fulfillment_fee = data.grand_fulfillment_fee
          this.warehouse.name = data.warehouse_name
          this.warehouse.location = data.warehouse_location.toLowerCase()

          const result = []
          const orderCount = {}
          const productCount = {}

          data.order_summary.forEach((order, index) => {
            order.products.forEach((product, index2) => {
              if (product.is_bundling && product.product_bundles.length > 0) {
                product.product_bundles.forEach(bundle => {
                  const orderNumber = index + 1
                  const productId = `${index2}_${product.product_id}`

                  if (!orderCount[orderNumber]) {
                    orderCount[orderNumber] = 0
                  }
                  orderCount[orderNumber] += 1

                  if (!productCount[orderNumber]) {
                    productCount[orderNumber] = {}
                  }
                  if (!productCount[orderNumber][productId]) {
                    productCount[orderNumber][productId] = 0
                  }
                  productCount[orderNumber][productId] += 1

                  result.push({
                    order_number: orderNumber,
                    outbound_detail_id: order.outbound_detail_id,
                    total_order: order.total_order,
                    total_order_approved: order.total_order_approved,
                    fulfillment_fee_per_order: order.fulfillment_fee_per_order,
                    product_id: productId,
                    bundle_id: bundle.product_id,
                    product_variant_id: bundle.product_variant_id,
                    product_name: product.product_name,
                    product_variant_name:
                      bundle.product_variant_name || bundle.product_name,
                    total_item: product.total_item,
                    is_bundling: product.is_bundling,
                    product_fulfillment: product.product_fulfillment,
                    packings: order.packings,
                  })
                })
              } else {
                const orderNumber = index + 1
                const productId = `${index2}_${product.product_id}`

                if (!orderCount[orderNumber]) {
                  orderCount[orderNumber] = 0
                }
                orderCount[orderNumber] += 1

                if (!productCount[orderNumber]) {
                  productCount[orderNumber] = {}
                }
                if (!productCount[orderNumber][productId]) {
                  productCount[orderNumber][productId] = 0
                }
                productCount[orderNumber][productId] += 1

                result.push({
                  order_number: orderNumber,
                  outbound_detail_id: order.outbound_detail_id,
                  total_order: order.total_order,
                  total_order_approved: order.total_order_approved,
                  fulfillment_fee_per_order: order.fulfillment_fee_per_order,
                  product_id: productId,
                  bundle_id: null,
                  product_variant_id: product.product_variant_id,
                  product_name: product.product_name,
                  product_variant_name: product.product_variant_name || '-',
                  total_item: product.total_item,
                  is_bundling: product.is_bundling,
                  product_fulfillment: product.product_fulfillment,
                  packings: order.packings,
                })
              }
            })
          })

          this.items = result.map((item, index, array) => {
            const orderNumber = item.order_number
            const productId = item.product_id

            let orderRowspan = 0
            let productRowspan = 0

            if (index === 0 || orderNumber !== array[index - 1].order_number) {
              orderRowspan = orderCount[orderNumber]
            }
            if (
              index === 0
              || orderNumber !== array[index - 1].order_number
              || productId !== array[index - 1].product_id
            ) {
              productRowspan = productCount[orderNumber][productId]
            }

            return {
              ...item,
              orderRowspan,
              productRowspan,
            }
          })
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    /**
     * Cancels the current packing process.
     */
    cancelPacking() {
      this.$swal({
        title: 'Yakin Batalkan Pengajuan Packing?',
        html: '<p class="m-0 text-[#626262]">Pengajuan packing Kamu akan dibatalkan dan tidak dapat diproses. Apakah Kamu yakin ingin melanjutkan?</p>',
        icon: 'warning',
        iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
        showCancelButton: true,
        cancelButtonText: 'Batalkan',
        confirmButtonText: 'Lanjutkan',
        reverseButtons: true,
        color: '#626262',
        customClass: {
          popup: 'md:!w-[600px] !py-12 !text-[#626262]',
          icon: 'border-0 !w-[100px]',
          title: 'my-2',
          htmlContainer: '!text-[#626262]',
          actions: '!flex flex-nowrap gap-3 mt-2',
          cancelButton: 'w-full btn btn-outline-primary px-5',
          confirmButton: 'w-full btn btn-primary px-4',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          try {
            const response = await this.$http_new.put(`/kompack/api/v1/outbounds/${this.refId ? this.refId : this.detailId}/cancel`)
            if (response.status !== 200) {
              throw new Error('gagal pengajuan pembatalan')
            }
            this.status = 'Dibatalkan'
            return true
          } catch (err) {
            this.$toast_error({ message: 'Pembatalan pengajuan gagal. Silakan coba lagi.' })
            return false
          }
        },
      })
    },
  },
}
</script>
